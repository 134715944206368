<template>
    <v-layout>
        <side-bar/>
        <!-- <tab-bar v-if="isMobile"/> -->
        <v-content>
            <v-fade-transition mode="out-in" appear>
                <router-view/>
            </v-fade-transition>
        </v-content>
    </v-layout>
</template>

<script>
    import SideBar from "../components/home/SideBar";
    import TabBar from "../components/home/TabBar";

    export default {
        name: "HomeView",
        components: {
            SideBar,
            TabBar
        },
        computed: {
            isMobile() {
                return this.$store.state.isMobile
            }
        },
        methods: {
            showNotification(icon, title, body, route, tag) {
                let router = this.$router;
                let notification = new Notification(title,
                    {
                        body: body,
                        tag: tag,
                        icon: icon
                    });
                notification.onclick = function (event) {
                    router.history.push(route);
                }
            },
            notify(icon, title, body, route, tag) {
                let self = this;
                if (!("Notification" in window)) {
                    alert("This browser does not support desktop notification");
                } else if (Notification.permission === "granted") {
                    self.showNotification(icon, title, body, route, tag)
                } else {
                    Notification.requestPermission(function (permission) {
                        if (permission === "granted") {
                            self.showNotification(icon, title, body, route, tag)
                        }
                    });
                }
            }
        },
        sockets: {
            reconnect() {
                console.log('here');
                this.$socket.client.emit('authorization', {token: localStorage.getItem('token')});
            },
            service(data) {
                console.log('service', data)
                switch (data.code) {
                    case -1:
                        //console.log("error")
                        this.$socket.client.emit('authorization', {token: localStorage.getItem('token')});
                        break;
                    case -2:
                        //console.log("error")
                        this.$router.history.push({name: 'login'});
                        break;
                    case 1:
                        console.log("connected");
                        break;
                }
            },
            newRequest(data) {
                this.notify(
                    require('../assets/logo.jpeg'),
                    'Автозапчасти',
                    'Новая заявка на верификацию',
                    {name: 'sellers', query: {page: 1, approve: 'true'}},
                    'request')
            }
        },
        beforeMount() {
            this.$socket.client.emit('authorization', {token: localStorage.getItem('token')});
        }
    }
</script>

<style scoped>

</style>