<template>
    <v-navigation-drawer app
                         fixed
                         class="primary"
                         :permanent="!isMobile"
                         light
    >
        <v-layout column justify-space-between fill-height>
            <v-list nav dense style="margin-top: 0;">
                <v-list-item
                        v-for="(t, i) in tabs"
                        :key="i"
                        :to="t.to"
                        link
                        :title="t.title"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ t.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list nav dense style="margin-top: 0;">
                <v-list-item
                        :to="profileTab.to"
                        link
                        :title="profileTab.title"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ profileTab.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-layout>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "SideBar",
        data() {
            return {
                mini: true
            }
        },
        computed: {
            drawer: {
                get() {
                    return this.$store.state.drawer
                },
                set() {
                    this.$store.dispatch('toggleDrawer')
                }
            },
            adminData() {
                return this.$store.state.adminData
            },
            isMobile() {
                return this.$store.state.isMobile
            },
            tabs() {
                let tabs = [
                    {title: 'Запросы', to: {name: 'requests'}},
                    {title: 'Покупатели', to: {name: 'users'}},
                    {title: 'Продавцы', to: {name: 'sellers'}},
                    {title: 'Транспорт', to: {name: 'cars'}},
                    {title: 'Статистика', to: {name: 'statistics'}}
                ];

                if (this.adminData.isRoot)
                    tabs.push({title: 'Администраторы', to: {name: 'settings'}});
                return tabs
            },
            profileTab() {
                return {title: 'Профиль', to: {name: 'profile'}}
            }
        }
    }
</script>

<style scoped>

</style>