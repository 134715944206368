<template>
    <v-app-bar app class="elevation-1" dark>
        <!--
        <v-app-bar-nav-icon>
            <v-btn @click="toggleDrawer" icon :title="drawer ? 'Свернуть' : 'Развернуть'">
                <v-icon>mdi-menu</v-icon>
            </v-btn>
        </v-app-bar-nav-icon>
        -->
        <v-icon large>mdi-settings-box</v-icon>
        <label class="title margin-title">Автозапчасти</label>
        <v-spacer/>
        <v-btn fab small color="error" @click="exit">
            <v-icon>mdi-location-exit</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
    export default {
        name: "TabBar",
        computed: {
            drawer() {
                return this.$store.state.drawer
            },
            width() {
                return this.$store.state.width
            },
            isMobile() {
                return this.$store.state.isMobile
            }
        },
        methods: {
            toggleDrawer() {
                if (this.drawer && this.width < 1260) {
                    this.$store.dispatch('toggleDrawer')
                    this.$nextTick().then(() => {
                        this.$store.dispatch('toggleDrawer')
                    })
                } else this.$store.dispatch('toggleDrawer')
            },
            exit() {
                this.$http.get("/profile/logout", {headers: {token: localStorage.getItem("token")}});
                this.$router.history.push({name: 'login'})
            }
        }
    }
</script>

<style scoped>
    .margin-title {
        margin-left: 5px;
    }
</style>